import React, { useCallback } from 'react'

import { Form } from '@wicadu/arepa/ui'
import { useLazyFetch } from '@wicadu/arepa/hooks'
import { navigate } from 'gatsby'

import { TemplateProvider } from '@hooks/useTemplate'
import LoginOrSignupTemplate from '@components/templates/LoginOrSignup'
import useAuth from '@hooks/useAuth'
import { LoginResponse } from '@ts-types/LoginResponse'
import isBrowser from '@utils/isBrowser'

const { useForm } = Form

const translate = {
  es: {
    TITLE: 'Ingresa tu email y contraseña',
    DESCRIPTION: 'Para continuar, es necesario que introduzcas tu correo y contraseña.',
    EMAIL_PLACEHOLDER: 'tu@correo.com',
    PASSWORD_PLACEHOLDER: 'Ingresa tu contraseña',
    BUTTON: 'Iniciar sesión',
    QUESTION: '¿No tienes una cuenta?',
    QUESTION_LINK: 'Regístrate',
  },
}

function LoginContainer() {
  const { logIn } = useAuth()
  const { setError, handleSubmit } = useForm()

  const onCompleted = useCallback(
    ({ accessToken }: LoginResponse): void => {
      logIn(accessToken)
    },
    [logIn]
  )

  const onError = useCallback(
    (err: Error) => {
      if (err.message.match('Invalid username'))
        return setError('username', {
          type: 'manual',
          message: 'No encontramos una cuenta asociada a este correo',
        })
      else if (err.message.match('Invalid password'))
        return setError('password', {
          type: 'manual',
          message: 'La contraseña no es válida',
        })

      // TODO: Throw default error
    },
    [setError]
  )

  const [login, { loading }] = useLazyFetch<LoginResponse>(
    `${process.env.OAUTH_SERVER_HOST}/api/auth/log-in`,
    {
      fetchOptions: {
        method: 'POST',
        headers: isBrowser() && new Headers({
          Accept: 'application/json',
          'Content-Type': 'application/json',
        }),
      },
      onCompleted,
      onError,
    }
  )

  const onSubmit = useCallback(
    handleSubmit(({ username, password }) => {
      login({
        body: {
          username,
          password,
        },
      })
    }),
    [handleSubmit, login]
  )

  const goToSwappedUrl = useCallback(() => navigate('/signup'), [])

  return (
    <TemplateProvider
      value={{
        translate,
        loading,
        onSubmit,
        goToSwappedUrl,
      }}>
      <LoginOrSignupTemplate />
    </TemplateProvider>
  )
}

export default LoginContainer
