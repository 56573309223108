import React, { useMemo } from 'react'
import { Form } from '@wicadu/arepa/ui'
import { yup, yupResolver } from '@wicadu/arepa/utils'
import PropTypes, { InferProps } from 'prop-types'

const propTypes = {
  children: PropTypes.node.isRequired,
  opts: PropTypes.object
}

type Props = InferProps<typeof propTypes>

const defaultProps = {
  opts: {}
}

const schema = yup.object().shape({
  username: (
    yup.string()
      .wicaduEmail('Ingresa un correo válido')
      .lowercase()
      .required('El campo es requerido')
  ),
  password: (
    yup.string()
      .min(7, 'Minimo 7 carácteres')
      .max(50, 'Máximo 50 carácteres')
      .required('El campo es requerido')
  )
})

function LoginForm ({
  children,
  opts
}: Props) {
  const newOpts = useMemo(() => ({
    mode: 'onSubmit',
    resolver: yupResolver(schema),
    ...opts
  }), [
    opts
  ])

  return (
    <Form opts={newOpts}>{children}</Form>
  )
}

LoginForm.propTypes = propTypes
LoginForm.defaultProps = defaultProps

export default LoginForm
