import React, { Fragment } from 'react'
import useAuth from '@hooks/useAuth'
import SEO from '@components/SEO'
import LoginContainer from '@containers/Login'
import LoginForm from '@HOCs/LoginForm'
import Redirect from '@components/encapsulators/Redirect'

function Login ({
  location
}) {
  const { accessToken } = useAuth()

  if (Boolean(accessToken)) return <Redirect />

  return (
    <Fragment>
      <SEO title='Iniciar sesión' />

      <LoginForm opts={{
        defaultValues: {
          username: location.state?.usernameValue
        }
      }}>
        <LoginContainer />
      </LoginForm>
    </Fragment>
  )
}

export default Login
